<template>
  <div class="flex step">
    <img :src="src" class="icon" alt="Step Icon"/>
    <div class="flex flex-col">
      <h4>{{ title }}</h4>
      <p>{{ body }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NextStep",
  props: {
    title: {
      default: "Missing Title",
      type: String
    },
    body: {
      default: "Missing Body",
      type: String
    },
    src: {
      default: null,
      type: String
    }
  }
}
</script>

<style scoped>

.step {
  margin-top: 72px;
  max-width: 548px;
}

.icon {
  margin-right: 20px;
}

@media only screen and (max-width: 920px) {

  .step {
    margin-top: 38px;
  }

}

</style>
