<template>
  <div class="w-full items-center flex flex-col" style="min-height: 100vh;">
    <div class="thanks-container flex flex-col items-start flex-grow">
      <button @click="() => $router.push('/')" class="flex items-center">
        <img class="mr-2" src="@/assets/back-arrow.svg">
        <span class="back-text">Back to home</span>
      </button>
      <h1 class="mt-8 info-header">
        Awesome
      </h1>
      <h2 class="mt-2">Here's what happens next</h2>

      <div class="step-container">
        <NextStep v-for="step in steps" :src="require(`../assets/${step.image}`)" :key="step.title" :title="step.title" :body="step.body" />
      </div>

      <div class="w-full flex justify-center">
        <FormButton color="inverted" :click="() => $router.push('/')"  text="Back to home" style="margin-top: 48px" />
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import NextStep from "@/components/NextStep";
import Footer from "@/components/Footer";
import FormButton from "@/components/form/FormButton";

export default {
  name: "Thanks",
  components: {FormButton, Footer, NextStep},
  data() {
    return {
      steps: [
        {
          title: '1 - Review',
          body: 'We’ll review your information and files  and let you know if everything looks good or if we need something else.',
          image: 'eye-icon.svg'
        },
        {
          title: '2 - Build',
          body: 'Once we have everything we need, we’ll start building out your menu. This can take up to 24 hours, but usually much faster.',
          image: 'notepad-icon.svg'
        },
        {
          title: '3 - Publish',
          body: 'We get your menu online and send you signs you can print out with your unique QR code.',
          image: 'airplane-icon.svg'
        },
        {
          title: '4 - Deploy',
          body: 'Place the signs on your tables and start delighting your guests.',
          image: 'wand-icon.svg',
        }
      ]
    }
  }
}
</script>

<style scoped>

.thanks-container {
  max-width: 100%;
  width: 840px;
  margin-top: 82px;
}

.back-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 4px 0;
}

.step-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 18px;
  padding-left: 18px;
}

@media only screen and (max-width: 920px) {

  .thanks-container {
    align-items: center!important;
  }

}

</style>
